@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
}

@layer base {
  @font-face {
    font-family: 'DM Sans', sans-serif;
  }
  @font-face {
    font-family: 'Poppins', sans-serif;
  }
}

@layer utilities {
  @variants responsive {
    .dm-h1 {
      font-family: 'DM Sans', sans-serif;
      font-size: 4.5rem;
      line-height: 4.5rem;
      font-weight: 700;
    }
    .dm-h2 {
      font-family: 'DM Sans', sans-serif;
      font-size: 3.5rem;
      line-height: 3.5rem;
      font-weight: 500;
    }

    .dm-h3 {
      font-family: 'DM Sans', sans-serif;
      font-size: 2rem; /* 32px */
      line-height: 2.5rem;
      font-weight: 400;
    }

    .dm-m-h2 {
      font-family: 'DM Sans', sans-serif;
      font-size: 2.5rem;
      line-height: 3.5rem;
      font-weight: 500;
    }

    .dm-m-h3 {
      font-family: 'DM Sans', sans-serif;
      font-size: 1.75rem;
      line-height: 2.5rem;
      font-weight: 400;
    }

    .pp-h3 {
      font-family: 'Poppins', sans-serif;
      font-size: 1.75rem;
      line-height: 2.5rem;
      font-weight: 400;
    }

    .pp-h4 {
      font-family: 'Poppins', sans-serif;
      font-size: 1.125rem;
      line-height: 1.5rem;
      font-weight: 600;
    }

    .pp-h5 {
      font-family: 'Poppins', sans-serif;
      font-size: 1.375rem;
      line-height: 2rem;
      font-weight: 600;
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__day--selected {
  background-color: #9e39ff !important;
  border-top: 0px;
  cursor: pointer;
}

.react-datepicker__day--in-range {
  background-color: #9e39ff !important;
}

.react-datepicker__day:hover {
  background-color: #c07eff !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: #ebd6ff !important;
  color: black !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #c07eff !important;
}

::-webkit-calendar-picker-indicator {
  display: none;
}

.swipeable-list-item__content {
  background-color: transparent !important;
}

viewer-toolbar {
  display: none;
}

iframe {
  width: 1rem !important;
  min-width: 100% !important;
}

embed {
  width: 1rem !important;
  min-width: 100% !important;
}

#toolbar {
  display: none !important;
}

#sizer {
  width: 1rem !important;
  min-width: 100% !important;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #9E39FF;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}



.lds-ring {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 28px;
  height: 28px;
  border: 4px solid #C07EFF;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #C07EFF transparent transparent transparent;
}
.lds-ring-big {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring-big div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  border: 4px solid #9E39FF;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #9E39FF transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.notifications-bg {
  background-color: #F5F7FB;
}